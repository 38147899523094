var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"新建活动","width":"500px","maskClosable":false},on:{"ok":_vm.submit},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-form-item',{attrs:{"label":"团长活动ID"}},[_c('af-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'campaign_id',
          {
            rules: [{ required: true, message: '请填写团长活动ID' }],
          } ]),expression:"[\n          'campaign_id',\n          {\n            rules: [{ required: true, message: '请填写团长活动ID' }],\n          },\n        ]"}],attrs:{"maxLength":10,"format":"number"}})],1),_c('a-form-item',{attrs:{"label":"团长活动名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'campaign_name',
          {
            rules: [{ required: true, message: '请填写团长活动名称' }],
          } ]),expression:"[\n          'campaign_name',\n          {\n            rules: [{ required: true, message: '请填写团长活动名称' }],\n          },\n        ]"}],attrs:{"maxLength":30}})],1),_c('a-form-item',{attrs:{"label":"报名时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'enroll_time',
          {
            rules: [{ required: true, message: '请选择报名时间' }],
          } ]),expression:"[\n          'enroll_time',\n          {\n            rules: [{ required: true, message: '请选择报名时间' }],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"活动时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'campaign_time',
          {
            rules: [{ required: true, message: '请选择活动时间' }],
          } ]),expression:"[\n          'campaign_time',\n          {\n            rules: [{ required: true, message: '请选择活动时间' }],\n          },\n        ]"}]})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }