<template>
  <div id="specialManage">
    <a-form layout="inline">
      <a-form-item>
        <a-input
          v-model.trim="searchParams.search"
          allowClear
          placeholder="请输入团长活动ID /名称"
        />
      </a-form-item>
      <a-form-item>
        <a-button @click="search" type="primary" html-type="submit" :loading="loading"
          >查询
        </a-button>
        <a-button
          style="margin-left: 20px"
          @click="campaign_tatch_update_goods_sync"
          type="primary"
          html-type="submit"
          :loading="loading"
          >同步活动
        </a-button>
      </a-form-item>
      <!--
      <div class="ptb-10">
        <a-button type="primary" v-auth="'operate_manage_special_add'" @click="showCreate"
          >新建活动
        </a-button>
      </div>-->
    </a-form>

    <dialog-create-active ref="dialogCreateActiveRef" @close="reload" />

    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="'campaign_manage'"
      :rowKey="record => record.id"
      :columns="Columns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ filterConfirm: '暂无数据' }"
      @change="handleTableChange"
    >
      <template slot="deadlineTime" slot-scope="record">
        <span>{{
          record.apply_deadline_time ? record.apply_deadline_time.substr(0, 16) : ''
        }}</span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button
          class="mr-10"
          type="primary"
          size="small"
          @click="goodsDetial(record)"
          v-auth="'operate_manage_special_modify'"
          >商品详情
        </a-button>
      </template>
      <template slot="service_rate" slot-scope="record"> {{ record.service_rate }}% </template>
    </a-table>
  </div>
</template>

<script>
import {
  campaign_list,
  addShop,
  editShop,
  campaign_productlist_sync_list,
} from '@/service/operate';
import dialogCreateActive from './dialogCreateActive';
import moment from 'moment';

/**
 * @Author: 矢车
 * @Date: 2020-07-08 10:08:51
 * @Description: 表格显示字段配置
 */
const Columns = [
  {
    title: '团长互动ID',
    fixed: 'left',
    dataIndex: 'campaign_id',
    width: 130,
  },
  {
    title: '团长活动名称',
    dataIndex: 'campaign_name',
    width: 160,
  },
  {
    title: '报名时间',
    dataIndex: 'enroll_time',
    width: 220,
  },
  {
    title: '活动时间',
    dataIndex: 'campaign_time',
    width: 220,
  },
  {
    title: '活动状态',
    dataIndex: 'campaign_status',
    align: 'center',
    width: 130,
  },
  {
    title: '折一提报商品数',
    dataIndex: 'total_count',
    align: 'center',
    width: 130,
  },
  {
    title: '已有活动链接商品数',
    dataIndex: 'has_campaign_link_count',
    align: 'center',
    width: 160,
  },
  {
    title: '操作',
    align: 'center',
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
  name: 'special',
  components: { dialogCreateActive },
  data() {
    return {
      tableScrollY: 600,
      loading: false,
      searchParams: {
        search: '',
      },
      visible: true,
      Columns,
      listLoading: true,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize,
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 275;
  },
  mounted() {
    this.getTopicList();
  },
  methods: {
    // 同步商品
    async campaign_tatch_update_goods_sync() {
      this.listLoading = true;
      const { err, res } = await campaign_productlist_sync_list({});
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          this.getTopicList();
          this.$message.success(
            res.data.detail || '商品活动同步时间较长，请难心等待，稍后请刷新页面。',
          );
        }
        /* else {
          this.$message.warning(res.message || '商品活动同步失败，请重新点击同步活动！！！');
        }*/
      }
    },
    showCreate() {
      this.$refs.dialogCreateActiveRef.show();
    },
    search() {
      this.pagination.current = 1;
      //  为 0 不传当前字段
      this.getTopicList(this.searchParams.search);
    },
    reload() {
      this.getTopicList(this.searchParams.search);
    },
    // 获取专题列表
    async getTopicList(search) {
      this.listLoading = true;
      const { err, res } = await campaign_list({
        search,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          this.list = res.data.results.map(item => {
            item.enroll_time = `${moment(item.enroll_start_time).format('YYYY/MM/DD')} - ${moment(
              item.enroll_end_time,
            ).format('YYYY/MM/DD')}`;
            item.campaign_time = `${moment(item.campaign_start_time).format(
              'YYYY/MM/DD',
            )} - ${moment(item.campaign_end_time).format('YYYY/MM/DD')}`;
            return item;
          });
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 分类
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getTopicList(this.searchParams.search);
    },
    goodsDetial(row) {
      this.$router.push({ path: '/admin/operate/head_activity/goods', query: { id: row.id } });
    },
  },
};
</script>

<style lang="less" scoped>
#specialManage {
  .ptb-10 {
    padding: 10px 0;
  }
}

.form_btn {
  display: flex;
  justify-content: center;
}

.mr-30 {
  margin-right: 30px;
}

.mr-10 {
  margin-right: 10px;
}
</style>
