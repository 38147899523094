<template>
  <a-modal @ok="submit" v-model="visible" title="新建活动" width="500px" :maskClosable="false">
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="团长活动ID">
        <af-input
          :maxLength="10"
          format="number"
          v-decorator="[
            'campaign_id',
            {
              rules: [{ required: true, message: '请填写团长活动ID' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="团长活动名称">
        <a-input
          :maxLength="30"
          v-decorator="[
            'campaign_name',
            {
              rules: [{ required: true, message: '请填写团长活动名称' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="报名时间">
        <a-range-picker
          v-decorator="[
            'enroll_time',
            {
              rules: [{ required: true, message: '请选择报名时间' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="活动时间">
        <a-range-picker
          v-decorator="[
            'campaign_time',
            {
              rules: [{ required: true, message: '请选择活动时间' }],
            },
          ]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { campaign_add } from '@/service/operate';
import moment from 'moment';
import { message } from 'ant-design-vue';

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      formData: {},
    };
  },
  methods: {
    show() {
      this.visible = true;
      this.formData = {};
      this.$nextTick(() => {
        this.form.resetFields();
      });
    },
    async submit() {
      try {
        const postData = await this.form.validateFields();
        postData.enroll_start_time = moment(postData.enroll_time[0]).format('YYYY-MM-DD 00:00:00');
        postData.enroll_end_time = moment(postData.enroll_time[1]).format('YYYY-MM-DD 23:59:59');
        postData.campaign_start_time = moment(postData.campaign_time[0]).format(
          'YYYY-MM-DD 00:00:00',
        );
        postData.campaign_end_time = moment(postData.campaign_time[1]).format(
          'YYYY-MM-DD 23:59:59',
        );
        const res = await campaign_add(postData);
        if (!res.res.success) {
          return;
        }
        message.success('添加成功');
        this.visible = false;
        this.$emit('close');
      } catch {
        return
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
